.page {
  @apply max-w-7xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8;
}

.page > h2 {
  @apply text-3xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight lg:text-5xl mb-10;
}

.page .body {
  @apply prose prose-indigo;
}

/* apply only inside paragraphs, not inside <pre> code blocks */
.page p code {
  @apply break-words;
}

.searchForm {
  @apply flex rounded-md border border-gray-300 focus-within:border-indigo-500;
}

.searchField {
  @apply appearance-none rounded-md min-w-0 w-full py-2 px-4 border-0 text-sm sm:text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-400;
}

.clearSearch {
  @apply rounded-md sm:mt-0 sm:flex-shrink-0;
}

.clearSearch > button {
  @apply w-full flex items-center justify-center pr-4 py-2 sm:py-3 border border-transparent text-sm sm:text-base text-gray-500 font-medium rounded-md focus:outline-none hover:text-gray-600;
}

.blogPostsGrid {
  @apply mt-6 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none;
}
